<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.86682 1.0666V14.9334H1.06658V1.0666H5.86644H5.86682ZM5.86644 2.03452e-05H1.06658C0.477662 2.03452e-05 0 0.4777 0 1.0666V14.9334C0 15.2161 0.112355 15.4877 0.3125 15.6875C0.512283 15.8877 0.783857 16 1.0666 16H5.86684C6.14958 16 6.42078 15.8876 6.62094 15.6875C6.82109 15.4877 6.93344 15.2161 6.93344 14.9334V1.0666C6.93344 0.783855 6.82108 0.512281 6.62094 0.312499C6.42078 0.112344 6.14958 0 5.86684 0L5.86644 2.03452e-05Z" fill="white"/>
    <path d="M14.9336 1.0666V14.9334H10.1334V1.0666H14.9332H14.9336ZM14.9332 2.03452e-05H10.1334C9.54443 2.03452e-05 9.06677 0.4777 9.06677 1.0666V14.9334C9.06677 15.2161 9.17912 15.4877 9.37927 15.6875C9.57942 15.8877 9.85063 16 10.1334 16H14.9336C15.2164 16 15.4879 15.8876 15.6877 15.6875C15.8879 15.4877 16.0002 15.2161 16.0002 14.9334V1.0666C16.0002 0.783855 15.8879 0.512281 15.6877 0.312499C15.4879 0.112344 15.2164 0 14.9336 0L14.9332 2.03452e-05Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "IconColumns"
}
</script>

<style scoped>

</style>